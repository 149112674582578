
require('normalize.css/normalize.css');
require('./styles/index.scss');
require('./js/popup.js');


import TRAUIMap from './js/ui-map.js';

document.addEventListener('DOMContentLoaded', () => {

    window.onerror = function (msg, url, lineNo, columnNo, error) {
        // console.log(msg, url, lineNo, columnNo, error);
        return false;
    }

    const mapType = new URLSearchParams(window.location.search).get('mapType');

    // by default create heatmap
    let map = new TRAUIMap();
    map.init({
        type: mapType ? mapType : 'choropleth',

        // CUSTOMIZABLE PART

        iframeUrl: location.origin + '/tra1/index.html'
        // iframeUrl: location.origin + '/tra-integration-sources/coverage-maps-dist/tra1/index.html'
    });
});
