export default {
    titles: {
        default: '',
        networkCoverage: 'Network coverage map',
        internetSpeeds: 'Internet speeds map',
        usageTrends: 'Usage trends map',
    },
    buttons: {
        networkCoverage: 'Network Coverage',
        internetSpeeds: 'Internet Speeds',
        usageTrends: 'Usage trends',
        socialMedia: 'Social Media Experience'
    },
    inputs: {
        accessType: {
            title: 'Access type',
            wifi: 'wifi',
            cellular: 'cellular'
        },
        technology: {
            title: 'Technology',
            '2g': '2G',
            '3g': '3G',
            '4g': '4G',
            '5g': '5G'
        },
        isp: {
            title: 'ISP',
        },
        socialMedia: {
            title: 'Social Media'
        },
        monthPicker: {
            title: 'Period',
            months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
        },
        addressSearch: {
            title: 'Search by block',
            placeholder: 'Enter an address'
        }
    },
    fullScreenBtn: {
        enter: 'FullScreen View',
        exit: 'Minimize View',
    },
    toggleFormBtn: {
        show: 'Show Options',
        hide: 'Hide Options'
    }
}
