const DATE_RANGE = {
    '6_MONTHS': '9999-06-01',
    '3_MONTHS': '9999-03-01',
    '30_DAYS': '9999-01-01',
}

const ISP_ALL = '99999';

export default {

    /** Calculate map height after form was rendered. height = bodyHeight - formHeight. Page should be without scroll */
    calculateMapHeight(mapContainer) {
        let result;
        const width = mapContainer.offsetWidth;
        const delta = width < 768 ? 0 : 5;
        let entireHeight = document.querySelector('body').offsetHeight;
        let elementSiblings = this.getSiblings(mapContainer);

        let siblingHeight = 0;
        elementSiblings.forEach(element => {
            siblingHeight += element.offsetHeight;
        });

        result = entireHeight - siblingHeight - delta;
        return result;
    },

    getSiblings(elem) {
        // Setup siblings array and get the first sibling
        var siblings = [];
        var sibling = elem.parentNode.firstChild;

        // Loop through each sibling and push to the array
        while (sibling) {
            if (sibling.nodeType === 1 && sibling !== elem) {
                siblings.push(sibling);
            }
            sibling = sibling.nextSibling;
        }

        return siblings;
    },

    getHtmlRadio(data, options) {
        let html = options.nlsAttributeValues.title ? `<span class="${options.titleCss}" data-nls="${options.nlsAttributeValues.title}"></span>` : '';

        let array = data;

        // Save additional fields. They may be set on frontend side, eg 'All'
        if (options.additionalFields && options.additionalFields.length) {
            array = options.additionalFields.concat(data);
        }

        // by default select first element, if no selected index in options
        let indexToSelect = options.indexToSelect != undefined ? options.indexToSelect : 0;

        // Set nls, name, value attributes
        array.forEach((element, index) => {
            if (element.Value != options.hiddenValue) {
                const css = element.css ? element.css : '';
                const text = !options.hideText ? element.Value : '';
                if (index === indexToSelect) {
                    html += `
							<label class="label-radio-filter ${css}">
								<input type="radio" name=${options.name} value="${element.ID}" checked="checked">
								<span class="checkmark" data-nls="${options.nlsAttributeValues.elements[element.ID]}">
									${text}
								</span>
							</label>`;
                }
                else {
                    html += `
							<label class="label-radio-filter ${css}">
								<input type="radio" name=${options.name} value="${element.ID}">
								<span class="checkmark" data-nls="${options.nlsAttributeValues.elements[element.ID]}">
									${text}
								</span>
							</label>`;
                }
            }
        });

        return html;
    },

    getHtmlRadioMock(data, options) {
        let html = ``;

        let array = data;

        array.forEach((element, index) => {
            html += `
				<label class="label" label-mock>
					<span>
						${element.Value}
					</span>
					<span class="checkmark" checkmark-mock></span>
				</label>`;
        });

        return html;
    },

    getHtmlStringSelectNative(data, options) {
        let htmlOptions = ``;

        let array = data;
        if (options.additionalFields && options.additionalFields.length) {
            array = options.additionalFields.concat(data);
        }

        array.forEach((element, index) => {
            const appendHTMLAttribute = element.appendHTMLAttribute ? element.appendHTMLAttribute : '';

            if (index === 0) {
                htmlOptions += `
						<option value=${element.ID} ${appendHTMLAttribute} selected>
							${element.Value.charAt(0).toUpperCase() + element.Value.slice(1)}
						</option>
						`;
            } else {

                // TODO improve
                if (element.Value == 'stc') {
                    htmlOptions += `
						<option value=${element.ID} ${appendHTMLAttribute}>
                            ${element.Value.toUpperCase()}
						</option>
						`;
                } else {
                    htmlOptions += `
						<option value=${element.ID} ${appendHTMLAttribute}>
                            ${element.Value.charAt(0).toUpperCase() + element.Value.slice(1)}
						</option>
						`;
                }
            }
        });

        let html = `<span class="${options.titleCss}" data-nls="${options.nlsAttributeValues.title}"></span>
				<div class="custom-select">
					<select name=${options.name}>
						${htmlOptions}
					</select>
                </div>`;
        return html;
    },

    getHtmlStringMonthPickerNative(data, options) {
        let html = `
			<span class="${options.titleCss}" data-nls="${options.nlsAttributeValues.title}"></span>
			<div id="${options.id}" class="custom-month-picker">
				<input name="${options.nameStartDate}" type="text">
				<input name="${options.nameEndDate}" type="text">
			</div>`;

        return html;
    },

    getQueryParamsFromUI(form, elementsToSkip) {
        // console.log('elementsToSkip', elementsToSkip);
        let result = {};

        for (let i = 0; i < form.elements.length; i++) {
            let e = form.elements[i];
            // console.log('e', e);
            if (e.type === 'radio' && e.checked) {
                result = Object.assign(result, {
                    [e.name]: e.value
                });
            }

            else if (e.type === 'select-one') {
                result = Object.assign(result, {
                    [e.name]: e.value
                });
            }

            else if (e.type === 'text') {
                result = Object.assign(result, {
                    [e.name]: e.value
                });
            }

            // don't send parameters for hidden elements
            const closest = e.closest('.hidden') || e.closest('.visibility-hidden');

            if (closest ||
                (elementsToSkip != undefined && elementsToSkip.includes(e.name))
            ) {

                delete result[e.name];
                // result = Object.assign(result, {
                //     [e.name]: ''
                // });
            }
        }

        result.governatureLevel = false;

        // 30 days selected - lets zoom out
        if (result.startDate === DATE_RANGE["30_DAYS"] && result.endDate === DATE_RANGE["30_DAYS"]) {
            result.governatureLevel = true;
        }

        // when one ISP selected and 3 months - there is not much to show - we should zoom out
        if (result.ispID !== ISP_ALL && result.startDate !== DATE_RANGE["6_MONTHS"] && result.endDate !== DATE_RANGE["6_MONTHS"]) {
            result.governatureLevel = true;
        }

        // if wifi is selected and one ISP - we again should zoom out to governorates
        if (result.accessTypeID == '1' && result.ispID !== ISP_ALL) {
            result.governatureLevel = true;
        }

        // convert to new api format https://3.basecamp.com/3483584/buckets/14372262/todos/6186318068#__recording_6510989186
        if (result.accessTypeCellularID == 1) {
            result.accessTypeSubID = 99999;
        } else if (result.accessTypeCellularID == 2) {
            result.accessTypeSubID = 5;
            result.governatureLevel = true;
        }

        if (result.accessTypeWifiID == 1) {
            result.accessTypeSubID = 1;
        } else if (result.accessTypeWifiID == 2) {
            result.accessTypeSubID = 2;
            result.governatureLevel = true;
        }

        delete result.accessTypeCellularID;
        delete result.accessTypeWifiID;


        return result;
    },

    getMonthPickerData(months) {

        let result = [];

        let todayDate = new Date();
        let prevMonthDate = new Date(todayDate.getFullYear(), todayDate.getMonth() - 1);

        let monthsCount = 0;
        for (let index = 0; index < monthsCount; index++) {

            const year = prevMonthDate.getFullYear();
            const month = this.getMonth(prevMonthDate);
            const firstDate = this.getDate(prevMonthDate);
            const lastDate = new Date(year, month, 0).getDate();

            result.push({
                index: index,
                label: `${months[month - 1]}, ${year}`,
                startDate: `${year}-${month}-${firstDate}`,
                endDate: `${year}-${month}-${lastDate}`,
                selected: false
            });

            prevMonthDate.setMonth(prevMonthDate.getMonth() - 1);
        }

        result.sort((a, b) => a.index - b.index);



        result.push(
            {
                index: result.length + 1,
                label: `Last year`,
                startDate: '9923-01-01',
                endDate: '9923-01-01',
                selected: true
            },
            {
                index: result.length + 2,
                label: `Q3 2023`,
                startDate: '9999-03-01',
                endDate: '9999-03-01',
            },
            {
                index: result.length + 3,
                label: `Last 6 months`,
                startDate: '9999-06-01',
                endDate: '9999-06-01'
            }
        );

        return result;
    },

    getMonth(dateObj) {
        let result = dateObj.getMonth() + 1;
        if (result < 10) {
            result = '0' + result;
        }
        return result;
    },

    getDate(dateObj) {
        let result = dateObj.getDate();
        if (result < 10) {
            result = '0' + result;
        }
        return result;
    }
};
