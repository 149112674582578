const URL = {
    // dev version
    ACCESS_TYPE_LIST: 'https://trabh-dev.speedcheckerapi.com/Map/GetAccessTypeList',
    WIFI_VALUE_TYPE_LIST: 'https://trabh-dev.speedcheckerapi.com/Map/GetWifiValueTypeList',
    ISP_LIST: 'https://trabh-dev.speedcheckerapi.com/Map/GetISPList',
    SPEED_VALUE_TYPE_LIST: 'https://trabh-dev.speedcheckerapi.com/Map/GetSpeedValueTypeList'

    // prod version
    // ACCESS_TYPE_LIST: 'https://maps.tra.bh.speedchecker.com/Map/GetAccessTypeList',
    // WIFI_VALUE_TYPE_LIST: 'https://maps.tra.bh.speedchecker.com/Map/GetWifiValueTypeList',
    // ISP_LIST: 'https://maps.tra.bh.speedchecker.com/Map/GetISPList',
    // SPEED_VALUE_TYPE_LIST: 'https://maps.tra.bh.speedchecker.com/Map/GetSpeedValueTypeList'
};

export default {
    getAccessTypeList() {
        return sendRequest({
            method: 'GET',
            url: URL.ACCESS_TYPE_LIST
        });
    },

    getWifiValueTypeList() {
        return sendRequest({
            method: 'GET',
            url: URL.WIFI_VALUE_TYPE_LIST
        });
    },

    getISPList() {
        return sendRequest({
            method: 'GET',
            url: URL.ISP_LIST
        });
    }
};

function sendRequest(options) {
    return new Promise((resolve, reject) => {
        let xhr = new XMLHttpRequest();
        let url = options.url;

        if (options.method === 'GET' && options.queryParams != undefined) {
            const queryString = new URLSearchParams(options.queryParams);
            url += '?' + queryString;
        }

        xhr.open(options.method, url);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onreadystatechange = function () {
            if (xhr.readyState == XMLHttpRequest.DONE) {
                if (xhr.status == 200) {
                    resolve(JSON.parse(xhr.response));
                } else {
                    reject();
                }
            }
        };

        switch (options.method) {
            case 'GET':
                xhr.send();
                break;

            case 'POST':
                xhr.send(options.data);
                break;

            default:
                break;
        }
    });
}
