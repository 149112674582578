import en from './en.js';
import ar from './ar.js';

/**
 * In html add attribute 'data-nls' with value path to translation. 
 * For example, translate defined in object 
 * { titles: {
 *      networkCoverage: 'Network coverage map',
 *      usageTrends: 'Usage trends map'
 *   }
 * }
 * 
 * Add to html data-nls="titles.networkCoverage" or data-nls="titles.usageTrends"
 */


export default {
    translate(lang) {

        // en by default
        let nls = getNlsByLang(lang);


        // find all elements on the page with attribute data-nls-*
        let elementsToTranslate = document.querySelectorAll('[data-nls]');
        let length = elementsToTranslate.length;

        for (let index = 0; index < length; index++) {
            const element = elementsToTranslate[index];
            if (element == undefined) {
                return;
            }
            const attrValue = element.getAttribute('data-nls');
            if (attrValue == undefined) {
                return;
            }

            let value = this.getTranslationByAttr(attrValue, lang);
            if (value != undefined) {
                element.innerHTML = value;
            }
        }
    },

    // get translation by value from data-nls attribute
    getTranslationByAttr(attrValue, lang) {
        let nls = getNlsByLang(lang);

        const props = attrValue.split('.');
        let value = null;

        for (let index = 0; index < props.length; index++) {
            const currentProp = props[index];

            if (value != undefined && value[currentProp] != undefined) {
                value = value[currentProp];
            }

            else if (nls[currentProp] != undefined) {
                value = nls[currentProp];
            }
        }

        return value;
    }
}

function getNlsByLang(lang) {
    let nls = en;

    switch (lang) {
        case 'ar':
            nls = ar;
            break;

        default:
            break;
    }

    return nls;
}